import React, { FC } from 'react';

import { Alert, Container } from '@mui/material';
import { Button, Loading } from 'common/components';
import { ErrorBox } from 'common/components/_legacy/ErrorBox';
import { NavigationBar } from 'common/layout';
import { generateDynamicPath } from 'common/utils';
import { EventPreview } from 'event/management/components';
import { AppRoutes } from 'router';

import { usePublicEventDetails } from './hooks';

export const PublicEventDetailsPage: FC = () => {
  const { eventDetails, isLoading, error } = usePublicEventDetails();
  const registerUrl = generateDynamicPath(AppRoutes.EventDetails, { id: eventDetails?.id }, { register: true });

  if (isLoading) return <Loading />;

  if (!eventDetails || error) return <ErrorBox error={error || 'Sorry, there was an error during Event display'} />;

  return (
    <>
      <NavigationBar />
      <Container component="main" sx={{ overflow: 'auto', pt: 7, pb: 2 }}>
        <EventPreview
          {...eventDetails}
          actionButton={
            <Button size="large" href={registerUrl}>
              Sign up and Register for Event
            </Button>
          }
        >
          <Alert severity="info" sx={{ mb: 4 }}>
            To register for this Event, please create a Natter account.
          </Alert>
        </EventPreview>
      </Container>
    </>
  );
};
