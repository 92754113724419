import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { ChartType } from 'common/components/Charts';
import { convertNumberToAbbreviation, toPercentageString, toPluralForm } from 'common/utils';

type Props = { value: number; range?: [number, number]; totalCount: number; chartType?: ChartType };

export const SliderTooltip: FC<Props> = ({ value, range, totalCount, chartType }) => {
  const [min, max] = range ?? [];
  const isRange = min !== max;
  const name = isRange ? `${min} - ${max}` : `${min}`;
  const isDensityPlot = chartType === ChartType.DensityPlot;

  return (
    <Typography>
      <b>{isDensityPlot ? `${Math.round(value * 10000) / 100}%` : convertNumberToAbbreviation(value)}</b>
      {` ${isDensityPlot ? 'of ' : ''}${toPluralForm('participant', value)} `}
      {!isDensityPlot && <b>{`(${toPercentageString(value, totalCount)})`}</b>}
      {' selected value '}
      {isRange ? 'between ' : ''}
      <b>{name}</b>
    </Typography>
  );
};
