import React, { FC } from 'react';

import { CheckCompletedIcon } from 'icons';

import {
  ActiveIndicator,
  ActiveIndicatorContainer,
  CompletedIconContainer,
  InactiveIndicator,
} from './StateIndicator.styled';

export const TEST_IDS = {
  completed: 'state-indicator-completed',
  active: 'state-indicator-active',
  inactive: 'state-indicator-inactive',
};

type StateIndicatorProps = {
  isActive?: boolean;
  isCompleted?: boolean;
};

export const StateIndicator: FC<StateIndicatorProps> = ({ isActive, isCompleted }) => {
  if (isActive) {
    return (
      <ActiveIndicatorContainer data-testid={TEST_IDS.active}>
        <ActiveIndicator />
      </ActiveIndicatorContainer>
    );
  }

  if (isCompleted) {
    return (
      <CompletedIconContainer alignItems="center" justifyContent="center" data-testid={TEST_IDS.completed}>
        <CheckCompletedIcon />
      </CompletedIconContainer>
    );
  }

  return <InactiveIndicator data-testid={TEST_IDS.inactive} />;
};
