import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { convertNumberToAbbreviation } from 'common/utils';

type Props = {
  name: string;
  value: number;
};

export const TextQuestionTooltip: FC<Props> = ({ name, value }) => (
  <Stack gap={1}>
    <Typography fontStyle="italic">{`"${name}"`}</Typography>
    <Typography>
      <b>{`${convertNumberToAbbreviation(value)}%`}</b>
      {' of participants'}
    </Typography>
  </Stack>
);
