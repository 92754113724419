import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { SectionHeader } from 'common/layout';

import { CopyTextField } from '../CopyTextField';
import { Dialog } from '../Dialog/Dialog';

type ShareEventProps = {
  title: string;
  urlName: string;
  urlDescription: string;
  isOpen: boolean;
  handleClose(): void;
  url: string;
  pin: string | null | undefined;
  pinName?: string;
};

export const ShareDialog: FC<ShareEventProps> = ({
  title,
  urlName,
  urlDescription,
  url,
  pin,
  pinName,
  isOpen,
  handleClose,
}) => (
  <Dialog
    isOpen={isOpen}
    onClose={handleClose}
    maxWidth="xs"
    data-testid="shareDialogRoot"
    title={title}
    confirmLabel="Close"
    onConfirm={handleClose}
    showCancelButton={false}
    showCloseButton={false}
    centered
  >
    <Stack gap={3}>
      <Stack gap={2}>
        <SectionHeader title={urlName} description={urlDescription} />
        <CopyTextField text={url} />
      </Stack>
      {pin && (
        <Stack gap={2}>
          {pinName && <SectionHeader title={pinName} />}
          <CopyTextField text={pin} />
        </Stack>
      )}
    </Stack>
  </Dialog>
);
