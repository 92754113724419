import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { ErrorIcon, InfoOutlinedIcon } from 'icons';

import { Wrapper } from './FormFieldHint.styled';

type Props = {
  error?: boolean;
  justifyContent?: 'center' | 'start';
  noWrap?: boolean;
};

export const FormFieldHint: FC<Props> = ({ children, error, justifyContent = 'start', noWrap }) =>
  children ? (
    <Wrapper justifyContent={justifyContent} data-testid="FormFieldHint">
      {error ? <ErrorIcon fontSize="small" color="error" /> : <InfoOutlinedIcon fontSize="small" />}
      <Typography component="div" variant="natter-text-xs" color={error ? 'error' : undefined} noWrap={noWrap}>
        {children}
      </Typography>
    </Wrapper>
  ) : null;
