import React, { FC } from 'react';

import { Box, Stack } from '@mui/material';
import { LinkifyText } from 'common/components';
import { SectionHeader } from 'common/layout';

export const AnswerItem: FC<{
  title: string;
  isObligatory: boolean;
  description?: string;
}> = ({ title, isObligatory, description, children }) => (
  <Stack gap={2}>
    <SectionHeader
      description={description ? <Box mt={-1}>{description}</Box> : undefined}
      title={<LinkifyText>{title}</LinkifyText>}
      required={isObligatory}
    />
    <Box width="100%">{children}</Box>
  </Stack>
);
