import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { convertNumberToAbbreviation, toPercentageString } from 'common/utils';

type Props = {
  name: string;
  value: number;
  totalCount: number;
  dataKey?: string;
  isPercentageValue?: boolean;
};

export const ChartTooltipContent: FC<Props> = ({ name, value, totalCount, dataKey, isPercentageValue }) => (
  <Stack gap={1}>
    <Typography>{name}</Typography>
    {isPercentageValue ? (
      <>
        <Typography fontWeight="bold">{`${convertNumberToAbbreviation(value)}%`}</Typography>
        <Typography>{dataKey}</Typography>
      </>
    ) : (
      <Typography fontWeight="bold">{`${value} (${toPercentageString(value, totalCount)})`}</Typography>
    )}
  </Stack>
);
