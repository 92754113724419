import React, { FC, useState } from 'react';

import { IconButton, Tooltip } from 'common/components';
import { useCopyToClipboard, useTimeout } from 'common/hooks';
import { CopyIcon } from 'icons';

const COPY_TOOLTIP_TIMEOUT = 1_000;

type CopyButtonProps = {
  value: string;
};

export const CopyButton: FC<CopyButtonProps> = ({ value }) => {
  const [tooltipText, setTooltipText] = useState('');
  const [open, setOpen] = useState(false);

  const { reset } = useTimeout(() => setOpen(false), COPY_TOOLTIP_TIMEOUT);

  const { copyToClipboard } = useCopyToClipboard({
    value,
    onSuccess: () => {
      setTooltipText('Copied to clipboard');
      setOpen(true);
      reset();
    },
    onError: () => {
      setTooltipText('Could not copy to clipboard. Please try again.');
      setOpen(true);
      reset();
    },
  });

  return (
    <Tooltip title={tooltipText} open={open} placement="top">
      <IconButton variant="text" onClick={copyToClipboard}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
};
