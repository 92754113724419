import {
  ArticleOutlinedIcon,
  BarChartRoundedIcon,
  Groups2OutlinedIcon,
  QueryStatsOutlinedIcon,
  TimelineOutlinedIcon,
  VerifiedOutlinedIcon,
} from 'icons';
import type { InsightsReportKeyMetric } from 'pages/InsightsReport/types';

export const surveyKeyMetrics: InsightsReportKeyMetric[] = [
  {
    key: 'participants',
    title: 'Invited Participants',
    icon: Groups2OutlinedIcon,
  },
  {
    key: 'surveysCompleted',
    title: 'Surveys Completed',
    icon: VerifiedOutlinedIcon,
  },
  {
    key: 'surveysStarted',
    title: 'Surveys Started',
    icon: ArticleOutlinedIcon,
  },
  {
    key: 'dataPoints',
    title: 'Data Points',
    icon: BarChartRoundedIcon,
  },
  {
    key: 'completionRate',
    title: 'Completion Rate',
    icon: TimelineOutlinedIcon,
    isPercentage: true,
    tooltip: '# of Surveys completed / # of Surveys started',
  },
  {
    key: 'responseRate',
    title: 'Response Rate',
    icon: QueryStatsOutlinedIcon,
    isPercentage: true,
    tooltip: '# of Surveys completed / # of Participants invited',
  },
];
