import React, { FC } from 'react';

import { Paper, Skeleton, Stack } from '@mui/material';
import { Explanation } from 'common/components';
import type { InsightsReportKeyMetric } from 'pages/InsightsReport/types';

import { getDisplayValue } from '../../utils';
import { KeyMetricIcon, KeyMetricTitle, KeyMetricValue } from './KeyMetricCard.styled';

type Props = {
  metric: InsightsReportKeyMetric;
  isLoading: boolean;
};

export const KeyMetricCard: FC<Props> = ({ metric, isLoading }) => (
  <Paper component="section" sx={{ position: 'relative', p: 2 }} aria-label={metric.title}>
    <KeyMetricIcon>
      <metric.icon />
    </KeyMetricIcon>

    <Stack gap={0.5}>
      <KeyMetricValue data-testid="KeyMetricCard-value">
        {isLoading ? <Skeleton width="25%" /> : getDisplayValue(metric)}
      </KeyMetricValue>
      <Stack direction="row" alignItems="center" gap={1}>
        <KeyMetricTitle data-testid="KeyMetricCard-title">
          {metric.title} {metric.tooltip && <Explanation>{metric.tooltip}</Explanation>}
        </KeyMetricTitle>
      </Stack>
    </Stack>
  </Paper>
);
