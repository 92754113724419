import { TextAnswerTheme } from 'store/apis/insightsReport';

import { useExportChartDataToCSV } from '../../../ChartMoreMenu/hooks';

export const useExportThemesDataToCSV = (themes: TextAnswerTheme[]) => {
  const { exportAsCSV } = useExportChartDataToCSV(
    themes.map((theme) => ({
      name: theme.theme,
      'Theme Description': theme.description ?? 'Description missing',
      Count: theme.count,
    })),
    {
      labelName: 'Theme Name',
    }
  );

  return { exportAsCSV };
};
