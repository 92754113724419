import React, { ComponentType, FC, ReactElement } from 'react';
import { useHistory } from 'react-router';

import { BaseGuard } from 'common/components';
import { UserPrivilegeName } from 'domain/UserProfile';
import { useAuthorization } from 'modules/authorization/hooks';
import { Routes } from 'router';

export const withCommunityOwned =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { hasPrivilege } = useAuthorization();
    const history = useHistory();

    const handleClick = () => {
      history.push(Routes.CommunityCreator);
    };

    return (
      <BaseGuard
        shouldDisplay={hasPrivilege([UserPrivilegeName.MANAGE_COMMUNITY_MEMBERS])}
        title="You have no Community"
        description="In order to access this page you should create a Community."
        buttonLabel="Click here to create a Community"
        onClick={handleClick}
      >
        <WrappedComponent {...props} />
      </BaseGuard>
    );
  };
