import React, { FC, useMemo } from 'react';

import {
  Bar,
  CartesianGrid,
  LabelList,
  Legend,
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { BarChartLabel, TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT } from '../../constants';
import { useChartAxis, useChartColors, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateChartMargins, calculateTicks } from '../../utils';
import { calculateHistogramData } from './utils';

export const Histogram: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
  legendFormatter,
}) => {
  const classes = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const histogramData = useMemo(() => calculateHistogramData({ data, dataKeys }), [data, dataKeys]);
  const yTicks = useMemo(() => calculateTicks({ data: histogramData, dataKeys }), [histogramData, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();
  const xTicks = useMemo(
    () => Array.from({ length: histogramData.length + 1 }).map((_, index) => -0.5 + index),
    [histogramData]
  );
  const xDomain = [-0.5, histogramData.length - 0.5];

  const xTickFormatter = (_value: unknown, index: number) => {
    if (index >= histogramData.length) {
      const { x0, x1 } = histogramData[histogramData.length - 1];
      if (x0 === x1) return `${Number(x1) + 1}`;
      return `${x1}`;
    }
    return `${histogramData[index].x0}`;
  };

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <RechartsBarChart
        barCategoryGap={1}
        barGap={2}
        data={histogramData}
        margin={calculateChartMargins({ labelNames, labelValues })}
        layout="horizontal"
        className={classes.verticalChart}
      >
        <CartesianGrid vertical={false} />
        <XAxis hide dataKey="name" type="number" domain={xDomain} allowDataOverflow={true} />
        <XAxis
          xAxisId="ticks"
          type="number"
          domain={xDomain}
          allowDataOverflow={true}
          tickLine={false}
          axisLine={false}
          ticks={xTicks}
          tick={tickStyles}
          tickFormatter={xTickFormatter}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          padding={{ top: 10 }}
          interval={0}
          ticks={yTicks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          width={40}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && <Legend verticalAlign="top" align="right" formatter={legendFormatter} />}
        {dataKeys.map((key, dataKeyIndex) => {
          const color = getColor(dataKeyIndex);
          return (
            <Bar key={key} dataKey={key} fill={color} onMouseEnter={handleMouseEnter(key)}>
              <LabelList dataKey={key} fill={color} content={BarChartLabel} />
            </Bar>
          );
        })}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};
