import React, { FC } from 'react';

import { CheckCompletedIcon, ErrorOutlinedIcon } from 'icons';
import { AlertSeverity } from 'store/features/alerts';

export type SnackbarIconProps = {
  severity: AlertSeverity;
};

export const SnackbarIcon: FC<SnackbarIconProps> = ({ severity }) => {
  switch (severity) {
    case 'success':
      return <CheckCompletedIcon fontSize="small" color="success" />;
    case 'error':
      return <ErrorOutlinedIcon fontSize="small" color="error" />;
    default:
      return null;
  }
};
