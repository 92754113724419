import React, { FC } from 'react';

import { Stack } from '@mui/material';

import { Step, TaskStep } from './components';

type Props = {
  steps: Step[];
  activeStep: number;
  onChange(index: number, step: Step): void;
};

export const TaskStepper: FC<Props> = ({ steps, activeStep, onChange }) => (
  <Stack component="aside" gap={2}>
    {steps.map((step, index) => (
      <TaskStep key={index} onClick={() => onChange(index, step)} isActive={activeStep === index} {...step} />
    ))}
  </Stack>
);
