import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { ErrorOutlinedIcon } from 'icons';

export const ChartErrorFallback: FC = () => (
  <Stack justifyContent="center" height="100%" alignItems="center" gap={3} p={5}>
    <ErrorOutlinedIcon color="error" sx={{ fontSize: '55px' }} />
    <Stack gap={1} alignItems="center">
      <Typography variant="natter-text-md" fontWeight="bold" textAlign="center">
        Graph Generation Unsuccessful
      </Typography>
      <Typography variant="natter-text-sm" textAlign="center">
        We encountered an issue generating your graph. Please create another Report, or contact support.
      </Typography>
    </Stack>
  </Stack>
);
