import type { Components, Theme } from '@mui/material';

import { MuiAlert } from './MuiAlert';
import { MuiAppBar } from './MuiAppBar';
import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiAvatar } from './MuiAvatar';
import { MuiButton } from './MuiButton';
import { MuiButtonBase } from './MuiButtonBase';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiChip } from './MuiChip';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiDialog } from './MuiDialog';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDialogContentText } from './MuiDialogContentText';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiFormControlLabel } from './MuiFormControlLabel';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiIconButton } from './MuiIconButton';
import { MuiInputBase } from './MuiInputBase';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiLinearProgress } from './MuiLinearProgress';
import { MuiLink } from './MuiLink';
import { MuiListItemText } from './MuiListItemText';
import { MuiMenu } from './MuiMenu';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiPaginationItem } from './MuiPaginationItem';
import { MuiPaper } from './MuiPaper';
import { MuiPopper } from './MuiPopper';
import { MuiRadio } from './MuiRadio';
import { MuiRating } from './MuiRating';
import { MuiSelect } from './MuiSelect';
import { MuiSlider } from './MuiSlider';
import { MuiSwitch } from './MuiSwitch';
import { MuiTab } from './MuiTab';
import { MuiTableBody } from './MuiTableBody';
import { MuiTableCell } from './MuiTableCell';
import { MuiTableContainer } from './MuiTableContainer';
import { MuiTableRow } from './MuiTableRow';
import { MuiTableSortLabel } from './MuiTableSortLabel';
import { MuiTabs } from './MuiTabs';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiTooltip } from './MuiTooltip';
import { MuiTypography } from './MuiTypography';

export const components: Components<Theme> = {
  MuiAlert,
  MuiAppBar,
  MuiAutocomplete,
  MuiAvatar,
  MuiButton,
  MuiButtonBase,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiIconButton,
  MuiInputLabel,
  MuiLinearProgress,
  MuiLink,
  MuiListItemText,
  MuiMenu,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiPaginationItem,
  MuiPaper,
  MuiPopper,
  MuiRadio,
  MuiRating,
  MuiSelect,
  MuiSlider,
  MuiSwitch,
  MuiTab,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableRow,
  MuiTableSortLabel,
  MuiTabs,
  MuiToggleButton,
  MuiTooltip,
  MuiTypography,
  MuiInputBase,
};
