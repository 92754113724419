import { ElementType } from 'react';

import { Link, LinkProps, Paper, PaperProps, styled } from '@mui/material';

export const NavigationCardWrapper = styled(Paper)<PaperProps & { component?: ElementType }>(({ theme }) => ({
  borderRadius: 8,
  border: '1px',
  borderColor: theme.palette.divider,
  borderStyle: 'solid',
}));

export const NavigationCardHeader = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing(3, 3, 1, 3),
}));

export const NavigationCardList = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  padding: 0,

  '& .MuiTypography-root:first-child': {
    borderTop: 'none',
  },
});

export const NavigationCardListItem = styled(Link)<LinkProps & { component?: ElementType }>(
  ({ theme: { spacing, palette, typography } }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(1),
    alignItems: 'center',

    ...typography['natter-text-sm'],
    fontWeight: 500,

    color: 'inherit',

    padding: spacing(2, 3),
    borderTop: `1px solid ${palette.divider}`,

    '&:hover': {
      textDecoration: 'none',
    },

    '& .MuiSvgIcon-root': {
      flexShrink: 0,
      color: palette.grey[500],
    },
  })
);
