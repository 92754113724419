import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { TooltipContentProps } from 'common/components/Charts';
import { toPercentageString, toPluralForm } from 'common/utils';

type RankingTooltipProps = Pick<TooltipContentProps, 'name' | 'value'> & { totalCount: number };

export const MultipleChoiceTooltip: FC<RankingTooltipProps> = ({ name, value, totalCount }) => (
  <Typography>
    <b>{value}</b>
    {` ${toPluralForm('participant', value)} `}
    <b>{`(${toPercentageString(value, totalCount)})`}</b>
    {' selected option '}
    <b>{name}</b>
  </Typography>
);
