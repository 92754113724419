import React from 'react';

import { IconButton } from 'common/components';
import { HelpOutlinedIcon } from 'icons';

export const GoToHelpCenter = () => (
  <a title="Visit Natter Help Center" href="https://intercom.help/natter/en" target="_blank" rel="noreferrer">
    <IconButton size="large" variant="plain">
      <HelpOutlinedIcon />
    </IconButton>
  </a>
);
