import React, { type FC, useEffect } from 'react';
import { Switch } from 'react-router-dom';

import isValidProp from '@emotion/is-prop-valid';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { SentryRoute } from 'common/components';
import { PublicPageGuard } from 'common/guards';
import { NatterThemeProvider } from 'common/hocs';
import { generateDynamicPath } from 'common/utils';
import { MotionConfig } from 'framer-motion';
import { AlertsContainer, ApiErrorAlertContainer } from 'modules/alerts/components';
import { ErrorHandler } from 'modules/app/components';
import { PublicEventDetailsPage } from 'pages/PublicEventDetails';
import { PublicEventReport } from 'pages/PublicEventReport';
import { PublicSurveyReport } from 'pages/PublicSurveyReport';
import { SurveyPublicPage } from 'pages/Survey';
import { AppRoutes } from 'router';

import App from './App';
import { useIntercom } from './hooks';

export const PreApp: FC = () => {
  const { start } = useIntercom();

  useEffect(() => {
    start();
  }, [start]);

  return (
    <StyledEngineProvider injectFirst>
      <MotionConfig isValidProp={isValidProp}>
        <NatterThemeProvider>
          <CssBaseline />
          <Sentry.ErrorBoundary fallback={<ErrorHandler />}>
            <AlertsContainer context="APP" />
            <ApiErrorAlertContainer />
            <Switch>
              <SentryRoute
                path={[
                  AppRoutes.EventPublic,
                  AppRoutes.SurveyPublic,
                  AppRoutes.PublicEventReport,
                  AppRoutes.PublicSurveyReport,
                ]}
              >
                <SentryRoute
                  strict
                  path={AppRoutes.EventPublic}
                  render={() => (
                    <PublicPageGuard getRedirectUrl={(params) => generateDynamicPath(AppRoutes.EventDetails, params)}>
                      <PublicEventDetailsPage />
                    </PublicPageGuard>
                  )}
                />
                <SentryRoute
                  path={AppRoutes.SurveyPublic}
                  render={() => (
                    <PublicPageGuard getRedirectUrl={(params) => generateDynamicPath(AppRoutes.SurveyDetails, params)}>
                      <SurveyPublicPage />
                    </PublicPageGuard>
                  )}
                />
                <SentryRoute path={AppRoutes.PublicEventReport} render={() => <PublicEventReport />} />
                <SentryRoute path={AppRoutes.PublicSurveyReport} render={() => <PublicSurveyReport />} />
              </SentryRoute>
              <SentryRoute strict path="/" component={App} />
            </Switch>
          </Sentry.ErrorBoundary>
        </NatterThemeProvider>
      </MotionConfig>
    </StyledEngineProvider>
  );
};
