import { Typography, styled } from '@mui/material';
import type { TypographyWithComponent } from 'common/theme/typography';

export const KeyMetricValue = styled(Typography, { name: 'KeyMetricValue' })<TypographyWithComponent>(
  ({ theme: { palette, typography } }) => ({
    ...typography['natter-display-md'],
    color: palette.primary.main,
  })
);

KeyMetricValue.defaultProps = {
  component: 'span',
};

export const KeyMetricTitle = styled(Typography, { name: 'KeyMetricTitle' })<TypographyWithComponent>(
  ({ theme: { typography } }) => ({
    ...typography['natter-text-sm'],
    fontWeight: 500,
    color: '#687385',
  })
);

KeyMetricTitle.defaultProps = {
  component: 'span',
};

export const KeyMetricIcon = styled('div', { name: 'KeyMetricIcon' })(({ theme: { palette, spacing } }) => ({
  position: 'absolute',
  top: spacing(2),
  right: spacing(2),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: spacing(4.5),
  height: spacing(4.5),

  color: palette.grey[600],
  background: palette.grey[50],
  borderRadius: '50%',
}));
