import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import type { Answer, CreateAnswerRequest, QuestionDefinition } from 'domain/question';
import { mapAnswersToApi, mapQuestionToFormAnswer } from 'mappers/questions';
import { type AnyObjectSchema, object } from 'yup';

import { answersValidation } from './validators';

const questionsFormSchema = object({
  answers: answersValidation,
});

export type QuestionsFormValues = {
  answers: Answer[];
};

type UseQuestionsFormParams<T extends QuestionsFormValues> = {
  questions: QuestionDefinition[];
  defaultValues?: { [key: string]: unknown };
  schema?: AnyObjectSchema;
  onSubmit?(data: T, serializedData: CreateAnswerRequest[]): void;
};

export const useQuestionsForm = <T extends QuestionsFormValues = QuestionsFormValues>({
  questions,
  defaultValues,
  schema: extraSchema,
  onSubmit,
}: UseQuestionsFormParams<T>) => {
  const schema = extraSchema ? questionsFormSchema.concat(extraSchema) : questionsFormSchema;

  const methods = useForm<T>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.getDefault(),
      ...defaultValues,
    },
  });

  const { getValues, reset } = methods;

  useEffect(() => {
    reset({ ...getValues(), answers: questions.map(mapQuestionToFormAnswer) });
  }, [questions, reset, getValues]);

  const handleSubmit = methods.handleSubmit(
    (data) => {
      onSubmit?.(data, mapAnswersToApi(data.answers));
    },
    (errors) => {
      console.error(errors);
    }
  );

  return { methods, handleSubmit };
};
