import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { TooltipContentProps } from 'common/components/Charts';
import { convertNumberToAbbreviation } from 'common/utils';

type RankingTooltipProps = Pick<TooltipContentProps, 'name' | 'value' | 'dataKey'>;

export const BreakdownChartTooltip: FC<RankingTooltipProps> = ({ name, value, dataKey }) => (
  <Typography>
    <b>{`${convertNumberToAbbreviation(value)}%`}</b>
    {' of participants with tag '}
    <b>{name}</b>
    {' selected option '}
    <b>{dataKey}</b>
  </Typography>
);
