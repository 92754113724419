import { type ImageFile } from 'common/components/_legacy/Form';
import { PaginatedApiPayload, TagsCategory } from 'domain/Common';
import type {
  Community,
  CommunityApiPayload,
  CommunityMember,
  CommunityMemberApiPayload,
  CommunitySettings,
  CreateCommunityRequestBody,
  UpdateCommunityRequestBody,
} from 'domain/Community';
import { transformPaginatedApiPayload } from 'modules/api/utils';

interface Data {
  logo: ImageFile | ImageData;
  logoImageLink?: string;
  color?: {
    hex: string;
  };
  /** @deprecated */
  brandingColor?: string;
  name: string;
  tagsCategories?: (TagsCategory & { newTagName?: string })[];
  calendarPermissionsRequired: boolean;
  communitySettings?: CommunitySettings;
  defaultEventImageAttachmentId?: string | null;
}

export const mapCommunityToApi = (data: Data): UpdateCommunityRequestBody | CreateCommunityRequestBody => ({
  logoImageLink: data.logoImageLink ?? null,
  brandingColor: (data.color?.hex || data.brandingColor) as string,
  name: data.name,
  tagsCategories: data.tagsCategories?.map(({ id, name, isSingleSelection, tags, type }) => ({
    id,
    name,
    isSingleSelection,
    tags,
    type,
  })),
  calendarPermissionsRequired: data.calendarPermissionsRequired,
  communitySettings: data.communitySettings,
  defaultEventImageAttachmentId: data.defaultEventImageAttachmentId,
});

export const mapCommunityFromApi = (data: CommunityApiPayload): Community => ({
  id: data.id,
  hash: data.hash,
  name: data.name,
  ownerId: data.ownerId,
  tagsCategories: data.tagsCategories,
  brandingColor: data.brandingColor ?? undefined,
  calendarPermissionsRequired: data.calendarPermissionsRequired,
  communityMembersCount: data.communityMembersCount,
  logoImageLink: data.logoImageLink ?? undefined,
  logoImageLinkSmall: data.logoImageLinkSmall ?? undefined,
  defaultEventImage: data.defaultEventImage,
  communitySettings: data.communitySettings ?? {
    eventsPerMonthLimit: 0,
    membersLimit: 0,
    usersPerEventLimit: 0,
  },
});

export const mapCommunityMemberFromApi = (data: CommunityMemberApiPayload): CommunityMember => ({
  accountId: data.accountId,
  avatarLink: data.avatarLink ?? '',
  communityRole: data.communityRole,
  displayName: data.displayName ?? '',
  email: data.email ?? '',
  id: data.id,
  name: data.name ?? '',
  role: data.role ?? '',
  tagsCategories: data.tagsCategories ?? [],
  socials: data.socials,
  hasCronofyCredentials: data.hasCronofyCredentials,
  privileges: data.privileges ?? [],
  isOrganizationMember: data.isOrganizationMember ?? false,
});

export const mapPaginatedCommunityMembersFromApi = (
  response: PaginatedApiPayload<CommunityMemberApiPayload>
): PaginatedApiPayload<CommunityMember> => {
  const { data, totalCount, pageSize } = transformPaginatedApiPayload(response);
  const mappedData = data.map(mapCommunityMemberFromApi);
  return {
    data: mappedData,
    content: mappedData,
    totalCount,
    pageSize,
  };
};
