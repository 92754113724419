import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Stack } from '@mui/material';
import { Button } from 'common/components';
import { TextField } from 'common/components/ReactHookForm';
import { SectionHeader } from 'common/layout';

import { MergeThemesFormValues, ThemeFormField } from './schema';

export const MergeThemesFormStep2: FC<{
  onMerge(): void;
  onBack(): void;
  isLoading: boolean;
}> = ({ onBack, onMerge, isLoading }) => {
  const { formState } = useFormContext();
  const isMergeDisabled = !formState.isValid || isLoading;
  const themes: ThemeFormField[] = useWatch({ name: 'themes' });
  const themeNamesString = themes
    .filter((theme) => theme.isSelected)
    .map((theme) => `"${theme.theme}"`)
    .join(', ');

  return (
    <Stack gap={3}>
      <SectionHeader title="Name your merged Themes" description={`Name the selected themes: ${themeNamesString}.`} />
      <TextField name={'mergedThemesName' as keyof MergeThemesFormValues} label="Type name" />
      <Stack direction="row" gap={1} justifyContent="end">
        <Button variant="text" onClick={onBack} disabled={isLoading}>
          Back
        </Button>
        <Button disabled={isMergeDisabled} onClick={onMerge} isLoading={isLoading}>
          Merge
        </Button>
      </Stack>
    </Stack>
  );
};
