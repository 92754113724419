import React, { FC } from 'react';

import { IconButton, InputBaseComponentProps, Stack } from '@mui/material';
import { TriangleArrowDownIcon, TriangleArrowUpIcon } from 'icons';

type Props = {
  value: string;
  inputProps?: InputBaseComponentProps;
  onChange(event: { target: { value: string } }): void;
};

const getNewValue = (oldValue: number, increment: number, min: number, max: number) => {
  if (isNaN(Number(oldValue))) {
    return min;
  }
  if (oldValue < min) {
    return min;
  }
  if (oldValue > max) {
    return max;
  }
  return oldValue + increment;
};

export const NumericControls: FC<Props> = ({ value, inputProps, onChange }) => {
  const { min, max } = inputProps ?? {};
  const handleChange = (increment: number) => () => {
    const newValue = getNewValue(Number(value), increment, min, max);

    onChange({ target: { value: newValue.toString() } });
  };

  return (
    <Stack mr={-1}>
      <IconButton
        sx={{ width: 20, height: 20, visibility: value >= max ? 'hidden' : 'visible' }}
        onClick={handleChange(1)}
      >
        <TriangleArrowUpIcon />
      </IconButton>
      <IconButton
        sx={{ width: 20, height: 20, visibility: value <= min ? 'hidden' : 'visible' }}
        onClick={handleChange(-1)}
      >
        <TriangleArrowDownIcon />
      </IconButton>
    </Stack>
  );
};
