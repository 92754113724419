import { useCallback } from 'react';

import { BasicChartData, isChartNonDataKey } from 'common/components/Charts';
import { CsvRow, dataToCsvFile, stringifyCsvData } from 'common/utils';

type Config = {
  labelName?: string;
};

export const useExportChartDataToCSV = (data: BasicChartData, config?: Config) => {
  const exportAsCSV = useCallback(() => {
    if (data.length === 0) return;

    const dataKeys = Object.keys(data[0]).filter((key) => !isChartNonDataKey(key));
    const rows: CsvRow[] = [[config?.labelName ?? '', ...dataKeys]];

    rows.push(...data.map((dataPoint) => [dataPoint.name, ...dataKeys.map((key) => dataPoint[key] || 0)]));

    const csvString = stringifyCsvData(rows);

    dataToCsvFile(csvString, 'chartData.csv');
  }, [data, config?.labelName]);

  return { exportAsCSV };
};
