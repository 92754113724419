import React, { FC, useMemo } from 'react';

import { Grid } from '@mui/material';
import { BasicChartDataPoint, ChartType } from 'common/components/Charts';
import { uuid } from 'common/utils';
import { sumBy } from 'lodash';

import { ChartCard, ChartTooltipContent } from '../../components';
import { useInsightsReportContext } from '../../hooks';

const DemographicsChartTypes = [
  ChartType.Donut,
  ChartType.Pie,
  ChartType.Bar,
  ChartType.HorizontalBar,
  ChartType.VerticalStackedBar,
];

export const ParticipantDemographics: FC = () => {
  const { demographicData = [] } = useInsightsReportContext();
  const chartData = useMemo(
    () =>
      demographicData.map(({ tagCategoryType, tags }) => ({
        tagCategoryType,
        data: tags.map<BasicChartDataPoint>(({ tagName, count }) => ({
          name: tagName,
          value: count,
        })),
        totalCount: sumBy(tags, 'count'),
        chartId: uuid(),
      })),
    [demographicData]
  );

  return (
    <Grid container spacing={3}>
      {chartData.map(({ tagCategoryType, data, totalCount, chartId }) => (
        <Grid item key={tagCategoryType} xs={12} xl={6} id={chartId}>
          <ChartCard
            chartId={chartId}
            title={tagCategoryType}
            availableChartTypes={DemographicsChartTypes}
            chartProps={{
              data,
              TooltipContent: ({ name, value }) => (
                <ChartTooltipContent name={name} value={value} totalCount={totalCount} />
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
